<template>
    <div>
        <ValidationObserver ref="formModalValidate">
            <b-row>
                <b-col cols="12" md="2" lg="2">
                    <ValidationProvider
                        name="code"
                        rules="required"
                        v-slot="{ errors }"
                    >
                        <b-form-group :label="$t('code')">
                            <b-form-input
                                type="text"
                                v-model="formData.code"
                                :state="errors[0] ? false : null"
                            />
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="5" lg="5">
                    <ValidationProvider
                        name="faculty_code"
                        rules="required"
                        v-slot="{ errors }"
                    >
                        <b-form-group :label="$t('faculty')">
                            <faculty-selectbox
                                v-model="formData.faculty_code"
                                :validateError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="5" lg="5">
                    <ValidationProvider
                        name="department_code"
                        rules="required"
                        v-slot="{ errors }"
                    >
                        <b-form-group :label="$t('department')">
                            <department-selectbox
                                v-model="formData.department_code"
                                :faculty_code="formData.faculty_code"
                                :validateError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" lg="12">
                    <b-form-group :label="$t('name')">
                        <ValidationProvider
                            name="name"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <b-input-group class="mb-1" prepend="TR">
                                <b-form-input
                                    v-model="formData.name"
                                    :state="errors[0] ? false : null"
                                />
                            </b-input-group>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                                class="mb-2"
                            />
                        </ValidationProvider>
                        <ValidationProvider name="name_en" v-slot="{ errors }">
                            <b-input-group prepend="EN">
                                <b-form-input
                                    v-model="formData.name_en"
                                    :state="errors[0] ? false : null"
                                />
                            </b-input-group>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            />
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="6">
                    <b-form-group :label="$t('short_name')">
                        <ValidationProvider
                            name="short_name"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <b-input-group class="mb-1" prepend="TR">
                                <b-form-input
                                    v-model="formData.short_name"
                                    :state="errors[0] ? false : null"
                                />
                            </b-input-group>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                                class="mb-2"
                            />
                        </ValidationProvider>
                        <ValidationProvider
                            name="short_name_en"
                            v-slot="{ errors }"
                        >
                            <b-input-group prepend="EN">
                                <b-form-input
                                    v-model="formData.short_name_en"
                                    :state="errors[0] ? false : null"
                                />
                            </b-input-group>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            />
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="6">
                    <b-form-group :label="$t('diploma_name')">
                        <ValidationProvider
                            name="diploma_name"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <b-input-group class="mb-1" prepend="TR">
                                <b-form-input
                                    v-model="formData.diploma_name"
                                    :state="errors[0] ? false : null"
                                />
                            </b-input-group>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                                class="mb-2"
                            />
                        </ValidationProvider>
                        <ValidationProvider
                            name="diploma_name_en"
                            v-slot="{ errors }"
                        >
                            <b-input-group prepend="EN">
                                <b-form-input
                                    v-model="formData.diploma_name_en"
                                    :state="errors[0] ? false : null"
                                />
                            </b-input-group>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            />
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="6">
                    <b-form-group :label="$t('diploma_title')">
                        <ValidationProvider
                            name="diploma_title"
                            rules="required"
                            v-slot="{ errors }"
                        >
                            <b-input-group class="mb-1" prepend="TR">
                                <b-form-input
                                    v-model="formData.diploma_title"
                                    :state="errors[0] ? false : null"
                                />
                            </b-input-group>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                                class="mb-2"
                            />
                        </ValidationProvider>
                        <ValidationProvider
                            name="diploma_title_en"
                            v-slot="{ errors }"
                        >
                            <b-input-group prepend="EN">
                                <b-form-input
                                    v-model="formData.diploma_title_en"
                                    :state="errors[0] ? false : null"
                                />
                            </b-input-group>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            />
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" lg="6">
                    <b-form-group :label="$t('card_name')">
                        <ValidationProvider
                            name="card_name"
                            v-slot="{ errors }"
                        >
                            <b-input-group class="mb-1" prepend="TR">
                                <b-form-input
                                    v-model="formData.card_name"
                                    :state="errors[0] ? false : null"
                                />
                            </b-input-group>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                                class="mb-2"
                            />
                        </ValidationProvider>
                        <ValidationProvider
                            name="card_name_en"
                            v-slot="{ errors }"
                        >
                            <b-input-group prepend="EN">
                                <b-form-input
                                    v-model="formData.card_name_en"
                                    :state="errors[0] ? false : null"
                                />
                            </b-input-group>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            />
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12" md="4" lg="3">
                    <ValidationProvider
                        name="level"
                        rules="required"
                        v-slot="{ errors }"
                    >
                        <b-form-group :label="$t('level')">
                            <parameter-selectbox
                                v-model="formData.level"
                                :validateError="errors[0]"
                                code="program_levels"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4" lg="3">
                    <ValidationProvider
                        name="major_type"
                        rules="required"
                        v-slot="{ errors }"
                    >
                        <b-form-group :label="$t('major_type')">
                            <parameter-selectbox
                                v-model="formData.major_type"
                                :validateError="errors[0]"
                                code="major_types"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4" lg="3">
                    <ValidationProvider
                        name="mode_of_study"
                        rules="required"
                        v-slot="{ errors }"
                    >
                        <b-form-group :label="$t('mode_of_study')">
                            <parameter-selectbox
                                v-model="formData.mode_of_study"
                                :validateError="errors[0]"
                                code="mode_of_studies"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4" lg="3">
                    <ValidationProvider
                        name="language"
                        rules="required"
                        v-slot="{ errors }"
                    >
                        <b-form-group :label="$t('language')">
                            <parameter-selectbox
                                v-model="formData.language"
                                code="languages"
                                :validateError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4" lg="3">
                    <ValidationProvider
                        type="number"
                        name="duration"
                        rules=""
                        v-slot="{ errors }"
                    >
                        <b-form-group :label="$t('duration')">
                            <b-form-input
                                v-model="formData.duration"
                                :state="errors[0] ? false : null"
                            />
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4" lg="3">
                    <ValidationProvider
                        type="number"
                        name="max_duration"
                        rules=""
                        v-slot="{ errors }"
                    >
                        <b-form-group :label="$t('max_duration')">
                            <b-form-input
                                v-model="formData.max_duration"
                                :state="errors[0] ? false : null"
                            />
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4" lg="3">
                    <ValidationProvider
                        name="credit_limit"
                        rules=""
                        v-slot="{ errors }"
                    >
                        <b-form-group :label="$t('credit_limit')">
                            <b-form-input
                                type="number"
                                v-model="formData.credit_limit"
                                :state="errors[0] ? false : null"
                            />
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="4" lg="3">
                    <ValidationProvider
                        name="student_number_format"
                        rules=""
                        v-slot="{ errors }"
                    >
                        <b-form-group :label="$t('student_number_format')">
                            <b-form-input
                                v-model="formData.student_number_format"
                                :state="errors[0] ? false : null"
                            />
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="with_thesis" v-slot="{ errors }">
                        <b-form-group :label="$t('with_thesis')">
                            <thesis-selectbox
                                v-model="formData.with_thesis"
                                :validateError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider
                        name="head_of_program"
                        rules=""
                        v-slot="{ errors }"
                    >
                        <b-form-group :label="$t('head_of_program')">
                            <staff-auto-complete
                                v-model="formData.head_of_program"
                                :validateError="errors[0]"
                                :valueObject="headOfProgramObject"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider
                        name="status"
                        rules=""
                        v-slot="{ errors }"
                    >
                        <b-form-group :label="$t('status')">
                            <status-selectbox
                                v-model="formData.status"
                                :validateError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider
                        name="yoksis_status"
                        rules=""
                        v-slot="{ errors }"
                    >
                        <b-form-group :label="$t('yoksis_status')">
                            <parameter-selectbox code="yoksis_statuses"
                                v-model="formData.yoksis_status"
                                :validateError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="proficiency_point" rules="double|min_value:0|max_value:100" v-slot="{ errors }">
                        <b-form-group :label="$t('proficiency_point')">
                            <b-form-input
                                v-model="formData.prep_proficiency_success_point"
                                :state="errors[0] ? false : null"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="prep_module_success_point" rules="double|min_value:0|max_value:100" v-slot="{ errors }">
                        <b-form-group :label="$t('prep_module_success_point')">
                            <b-form-input
                                v-model="formData.prep_module_success_point"
                                :state="errors[0] ? false : null"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="3">
                    <ValidationProvider name="language" rules="" v-slot="{ errors }">
                        <b-form-group :label="$t('second_language')">
                            <parameter-selectbox
                                v-model="formData.second_language"
                                code="languages"
                                :validateError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="3">
                    <ValidationProvider name="second_prep_module_success_point" rules="double|min_value:0|max_value:100" v-slot="{ errors }">
                        <b-form-group :label="$t('second_prep_module_success_point')">
                            <b-form-input
                                v-model="formData.second_prep_module_success_point"
                                :state="errors[0] ? false : null"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6">
                    <ValidationProvider name="second_prep_proficiency_success_point" rules="double|min_value:0|max_value:100" v-slot="{ errors }">
                        <b-form-group :label="$t('second_prep_proficiency_success_point')">
                            <b-form-input
                                v-model="formData.second_prep_proficiency_success_point"
                                :state="errors[0] ? false : null"
                            />
                            <b-form-invalid-feedback v-if="errors[0]" v-html="errors[0]" />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                    <b-form-group :label="$t('earned_degree')">
                        <ValidationProvider
                            name="name"
                            rules=""
                            v-slot="{ errors }"
                        >
                            <b-input-group class="mb-1" prepend="TR">
                                <b-form-input
                                    v-model="formData.earned_degree"
                                    :state="errors[0] ? false : null"
                                />
                            </b-input-group>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                                class="mb-2"
                            />
                        </ValidationProvider>
                        <ValidationProvider name="name_en" v-slot="{ errors }">
                            <b-input-group prepend="EN">
                                <b-form-input
                                    v-model="formData.earned_degree_en"
                                    :state="errors[0] ? false : null"
                                />
                            </b-input-group>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            />
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                    <ValidationProvider
                        name="group_id"
                        rules=""
                        v-slot="{ errors }"
                    >
                        <b-form-group :label="$t('program_groups_lte')">
                            <program-groups-selectbox
                                v-model="formData.group_id"
                                :validateError="errors[0]"
                            />
                        </b-form-group>
                    </ValidationProvider>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                    <b-form-group
                        class="requirements-area"
                        :label="$t('requirements')"
                    >
                        <ValidationProvider
                            name="name"
                            rules=""
                            v-slot="{ errors }"
                        >
                            <b-input-group class="mb-1" prepend="TR">
                                <b-form-textarea
                                    v-model="formData.requirements"
                                    :state="errors[0] ? false : null"
                                />
                            </b-input-group>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                                class="mb-2"
                            />
                        </ValidationProvider>
                        <ValidationProvider name="name_en" v-slot="{ errors }">
                            <b-input-group prepend="EN">
                                <b-form-textarea
                                    v-model="formData.requirements_en"
                                    :state="errors[0] ? false : null"
                                />
                            </b-input-group>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            />
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
                <b-col cols="12" md="6" lg="6">
                    <b-form-group
                        class="objectives-area"
                        :label="$t('objectives')"
                    >
                        <ValidationProvider
                            name="name"
                            rules=""
                            v-slot="{ errors }"
                        >
                            <b-input-group class="mb-1" prepend="TR">
                                <b-form-textarea
                                    v-model="formData.objectives"
                                    :state="errors[0] ? false : null"
                                />
                            </b-input-group>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                                class="mb-2"
                            />
                        </ValidationProvider>
                        <ValidationProvider name="name_en" v-slot="{ errors }">
                            <b-input-group prepend="EN">
                                <b-form-textarea
                                    v-model="formData.objectives_en"
                                    :state="errors[0] ? false : null"
                                />
                            </b-input-group>
                            <b-form-invalid-feedback
                                v-if="errors[0]"
                                v-html="errors[0]"
                            />
                        </ValidationProvider>
                    </b-form-group>
                </b-col>
            </b-row>
            <div class="d-flex justify-content-center mt-2">
                <b-button
                    variant="primary"
                    @click="updateForm"
                    :disabled="formLoading"
                >
                    {{ $t("save") }}
                </b-button>
            </div>
        </ValidationObserver>
    </div>
</template>

<script>
// Components
import DepartmentSelectbox from "@/components/interactive-fields/DepartmentSelectbox";
import FacultySelectbox from "@/components/interactive-fields/FacultySelectbox";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import StaffAutoComplete from "@/components/interactive-fields/StaffAutoComplete";
import StatusSelectbox from "@/components/interactive-fields/StatusSelectbox";
import ThesisSelectbox from "@/components/interactive-fields/ThesisSelectbox";
import ProgramGroupsSelectbox from "@/components/interactive-fields/ProgramGroupsSelectbox";

// Services
import ProgramService from "@/services/ProgramService";

// Other
import { ValidationObserver, ValidationProvider } from "vee-validate";

export default {
    name: "ProgramsUpdateForm",
    components: {
        DepartmentSelectbox,
        FacultySelectbox,
        ParameterSelectbox,
        StaffAutoComplete,
        StatusSelectbox,
        ThesisSelectbox,
        ProgramGroupsSelectbox,
        ValidationObserver,
        ValidationProvider,
    },
    props: {
        formId: {
            type: String,
        },
    },
    data() {
        return {
            formLoading: false,
            formData: {
                code: null,
                faculty_code: null,
                department_code: null,
                name: null,
                name_en: null,
                short_name: null,
                short_name_en: null,
                diploma_name: null,
                diploma_name_en: null,
                diploma_title: null,
                diploma_title_en: null,
                card_name: null,
                card_name_en: null,
                level: null,
                major_type: null,
                mode_of_study: null,
                language: null,
                duration: null,
                max_duration: null,
                student_number_format: null,
                credit_limit: null,
                with_thesis: null,
                head_of_program: null,
                status: null,
                yoksis_status: null,
                group_id: null,
            },
            headOfProgramObject: {},
        };
    },
    async created() {
        await this.get(this.formId);
    },
    methods: {
        get(id) {
            ProgramService.get(id)
                .then((response) => {
                    let data = response.data.data;
                    let with_thesis = null;
                    if (data.with_thesis != null) {
                        with_thesis = data.with_thesis ? 1 : 0;
                    }
                    this.formData = { ...data, with_thesis };

                    this.headOfProgramObject = {
                        value: data.head_of_program,
                        text: data.head_of_program_name,
                    };
                })
                .catch((e) => {
                    this.showErrors(e);
                });
        },

        async updateForm() {
            const isValid = await this.$refs.formModalValidate.validate();
            if (isValid && this.formId) {
                this.formLoading = true;
                this.formData.prep_proficiency_success_point = Number(
                    this.formData.prep_proficiency_success_point
                );
                this.formData.prep_module_success_point = Number(
                     this.formData.prep_module_success_point
                );
                ProgramService.update(this.formId, this.formData)
                    .then((response) => {
                        this.$emit("updateFormSuccess", true);
                        this.$toast.success(
                            this.$t("api." + response.data.message)
                        );
                    })
                    .catch((e) => {
                        this.showErrors(e, this.$refs.formModalValidate);
                    })
                    .finally(() => {
                        this.formLoading = false;
                    });
            }
        },
    },
};
</script>

<style lang="scss">
.requirements-area,
.objectives-area {
    textarea {
        height: 100px;
    }
}
</style>
